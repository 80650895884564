import EventBus from '@/utils/events.js';
/**
 * refresh page
 */
export default {
  methods: {
    listenPageRefresh() {
      EventBus.$on('refresh-page', this.fetchData);
      this.$once('hook:destroyed', () => {
        EventBus.$off('refresh-page', this.fetchData);
      });
    }
  },
  created() {
    this.listenPageRefresh();
  }
};
