var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex space-x-2 items-center" },
    [
      _c("flat-pickr", {
        staticClass: "w-full",
        attrs: {
          config: _vm.configFromdateTimePicker,
          placeholder: "Başlangıç"
        },
        on: { "on-change": _vm.onFromChange },
        model: {
          value: _vm.fromDateInternal,
          callback: function($$v) {
            _vm.fromDateInternal = $$v
          },
          expression: "fromDateInternal"
        }
      }),
      _c("span", [_vm._v("-")]),
      _c("flat-pickr", {
        staticClass: "w-full",
        attrs: { config: _vm.configTodateTimePicker, placeholder: "Bitiş" },
        on: { "on-change": _vm.onToChange },
        model: {
          value: _vm.toDateInternal,
          callback: function($$v) {
            _vm.toDateInternal = $$v
          },
          expression: "toDateInternal"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }