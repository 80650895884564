/**
 * return shorter version of router's queries
 */
export default {
  computed: {
    $query() {
      return this.$route.query;
    }
  }
};
