<template>
  <div class="flex space-x-2 items-center">
    <flat-pickr
      :config="configFromdateTimePicker"
      v-model="fromDateInternal"
      placeholder="Başlangıç"
      @on-change="onFromChange"
      class="w-full"
    />
    <span>-</span>
    <flat-pickr :config="configTodateTimePicker" v-model="toDateInternal" placeholder="Bitiş" @on-change="onToChange" class="w-full" />
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';

export default {
  props: {
    fromDate: {
      default: null
    },
    toDate: {
      default: null
    }
  },
  watch: {
    fromDate: {
      immediate: true,
      handler(val) {
        this.fromDateInternal = val;
      }
    },
    toDate: {
      immediate: true,
      handler(val) {
        this.toDateInternal = val;
      }
    }
  },
  data() {
    return {
      fromDateInternal: null,
      toDateInternal: null,
      configFromdateTimePicker: {
        maxDate: null
      },
      configTodateTimePicker: {
        // minDate: new Date(),
        maxDate: null
      }
    };
  },
  methods: {
    onFromChange(selectedDates, dateStr, _instance) {
      this.$emit('update:fromDate', dateStr);
      this.$set(this.configTodateTimePicker, 'minDate', dateStr);
    },
    onToChange(selectedDates, dateStr, _instance) {
      this.$emit('update:toDate', dateStr);
      this.$set(this.configFromdateTimePicker, 'maxDate', dateStr);
    }
  },
  components: {
    flatPickr
  }
};
</script>
