<template>
  <div>
    <vx-card ref="filterCard" xtitle="Filtreler" class="user-list-filters mb-8">
      <div class="header flex justify-between mb-6">
        <h4>Filtreler</h4>
        <div>
          <vx-tooltip text="Filtreleri Temizle">
            <vs-button
              @click="resetFilters"
              color="dark"
              size="small"
              class="btn btn--action"
              type="flat"
              icon-pack="feather"
              icon="icon-rotate-ccw"
            ></vs-button>
          </vx-tooltip>
        </div>
      </div>
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">İşlem Yapan</label>
          <v-select
            :reduce="(item) => item.value"
            label="label"
            :options="userOptions"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="filterUser"
            class="mb-4 md:mb-0"
          >
            <div slot="no-options">Sonuç bulunamadı</div>
          </v-select>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">İşlem Tipi</label>
          <v-select
            :options="actionOptions"
            :reduce="(item) => item.value"
            label="label"
            :clearable="false"
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            v-model="filterAction"
            class="mb-4 md:mb-0"
          >
            <div slot="no-options">Sonuç bulunamadı</div>
          </v-select>
        </div>
        <div class="vx-col md:w-2/4 w-full">
          <label class="text-sm opacity-75">Tarih</label>
          <AuditFilterDate :fromDate.sync="filterFromDate" :toDate.sync="filterEndDate" />
        </div>
      </div>
    </vx-card>

    <div class="audit-list mt-8">
      <vx-card title="İşlemler">
        <AuditList :list="list"></AuditList>
      </vx-card>
    </div>
    <div class="con-pagination-table vs-table--pagination">
      <vs-pagination :total="getTotalPages" v-model="page"></vs-pagination>
    </div>
  </div>
</template>

<script>
import AuditList from '@/components/audit/AuditList.vue';
import AuditFilterDate from '@/components/audit/AuditFilterDate.vue';
import { usePagination, useQueryParams, usePageRefresh } from '@/mixins';
import { mapActions, mapGetters } from 'vuex';
import vSelect from 'vue-select';
export default {
  mixins: [usePagination({ moduleName: 'audit' }), useQueryParams, usePageRefresh],
  components: {
    vSelect,
    AuditFilterDate,
    AuditList
  },
  computed: {
    ...mapGetters('audit', ['list', 'filters', 'userOptions', 'actionOptions'])
  },
  data() {
    return {
      filterUser: '',
      filterAction: '',
      filterFromDate: null,
      filterEndDate: null
    };
  },
  watch: {
    filterUser(newVal) {
      if (newVal === this.$query.user) return;
      if (newVal === '') {
        this.changeRoute({ user: null });
      } else {
        this.changeRoute({ user: newVal });
      }
    },
    filterAction(newVal) {
      if (newVal === this.$query.action) return;
      if (newVal === '') {
        this.changeRoute({ action: null });
      } else {
        this.changeRoute({ action: newVal });
      }
    },
    filterFromDate: {
      handler() {
        this.dateWatch();
      }
    },
    filterEndDate: {
      handler() {
        this.dateWatch();
      }
    }
  },
  methods: {
    dateWatch() {
      if (this.filterFromDate === this.$query.fromDate && this.filterEndDate === this.$query.endDate) return;

      if (this.filterEndDate) {
        this.changeRoute({ fromDate: this.filterFromDate, endDate: this.filterEndDate });
      }
    },
    ...mapActions('audit', ['getList']),
    resetQueryParams() {
      this.$query.page = 1;
      delete this.$query.user;
      delete this.$query.action;
    },
    setDefaultQueryParams() {
      if (this.$query.hasOwnProperty('user')) return;
      if (this.$query.hasOwnProperty('action')) return;
      if (this.$query.hasOwnProperty('fromDate')) return;
      if (this.$query.hasOwnProperty('endDate')) return;
      if (!this.$query.hasOwnProperty('page') || !this.$query.hasOwnProperty('perPage')) {
        const queryParams = { ...this.getPaginationParams, ...this.$query };
        this.$router.replace({ query: { ...queryParams } });
      }
    },
    changeRoute(query, { reset, replace } = {}) {
      if (reset) {
        this.resetQueryParams();
      }
      const newPath = { query: { ...this.$query, ...query } };
      let cleanQuery = { query: {} };
      //null ise sil
      Object.keys(newPath.query).forEach(function(key) {
        if (newPath.query[key] !== null) {
          cleanQuery.query[key] = newPath.query[key];
        }
      });
      // router.push ya da router.replace
      const method = replace ? 'replace' : 'push';
      this.$router[method](cleanQuery);
    },

    resetFilters() {
      const queries = Object.keys(this.$query);
      const checkQueries = ['user', 'action', 'fromDate', 'endDate'];
      const hasQuery = queries.some((q) => checkQueries.includes(q)); // array icinde array arama
      if (hasQuery) this.changeRoute({ user: null, action: null, fromDate: null, endDate: null });
      this.filterFromDate = null; // tek basina route degistirmedigi icin
    },
    filterList() {
      this.$vs.loading();
      this.getList(this.fetchParams).finally(() => {
        this.$vs.loading.close();
      });
    },
    async fetchData() {
      this.setDefaultQueryParams();
      this.setPagination(this.$query);
      await this.filterList();
    }
  },

  async created() {
    if (this.$query.hasOwnProperty('user')) {
      this.filterUser = this.$query.user;
    }
    if (this.$query.hasOwnProperty('action')) {
      this.filterAction = this.$query.action;
    }
    if (this.$query.hasOwnProperty('fromDate')) {
      this.filterFromDate = this.$query.fromDate;
    }
    if (this.$query.hasOwnProperty('endDate')) {
      this.filterEndDate = this.$query.endDate;
    }

    this.fetchData();
  }
};
</script>

<style></style>
