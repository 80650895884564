var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "entry" }, [
    _c("div", {
      staticClass: "mt-3 whitespace-pre-wrap ",
      attrs: { id: "entryhtml" },
      domProps: { textContent: _vm._s(_vm.item.entry.entryData) }
    }),
    _vm.item.entry.entryImageUrls && _vm.item.entry.entryImageUrls.length
      ? _c(
          "div",
          { staticClass: "flex break-word my-2" },
          _vm._l(_vm.item.entry.entryImageUrls, function(img, index) {
            return _c("div", { key: index, staticClass: "w-1/5" }, [
              _c("img", {
                staticClass: "rounded image-entry pl-0 p-2",
                attrs: { src: _vm.getImageUrl(img, "75x75") }
              })
            ])
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }