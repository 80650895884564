/**
 * Pagination Mixin
 */

import { prepareQueryParamsMixin, prepareFetchParamsMixin } from '@/mixins/queryParamMixins';
import { mapGetters, mapMutations } from 'vuex';
export const usePagination = ({ moduleName }) => {
  return {
    mixins: [prepareQueryParamsMixin, prepareFetchParamsMixin],
    computed: {
      ...mapGetters(moduleName, ['pagination']),
      getTotalPages() {
        return Math.ceil(this.pagination.total / this.pagination.perPage);
      },
      page: {
        get() {
          var page = this.$route.query.page ? parseInt(this.$route.query.page) : 1;
          return page;
        },
        set(page) {
          if (page !== this.page) {
            const query = this.$route.query;
            this.setPagination({ page });
            this.$router.replace({ query: { ...query, page } });
          }
        }
      },
      getPaginationParams() {
        return { perPage: this.pagination.perPage, page: this.pagination.page };
      },
      useInUrlQueryPropList() {
        return this.prepareQueryParamsMixin({
          perPage: this.pagination.perPage,
          page: this.pagination.page
        });
      },
      fetchParams() {
        const pagination = this.prepareFetchParamsMixin({
          perPage: this.pagination.perPage,
          page: this.pagination.page
        });
        return { ...pagination };
      }
    },
    methods: {
      ...mapMutations(moduleName, ['setPagination'])
    }
  };
};
