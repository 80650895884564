import { isValidLimitQuery, isValidPageQuery } from '@/utils/routing';
/**
 * loop over all component props that uses in url
 * return only valid props, then you get only valid params in fetchParams(component computed prop)
 */
export default {
  methods: {
    prepareQueryParamsMixin(queryPropList) {
      let result = {};
      Object.keys(queryPropList).forEach((key) => {
        if (queryPropList[key]) {
          result[key] = queryPropList[key];
        }

        if (key === 'perPage' && !isValidLimitQuery(queryPropList[key])) {
          delete result[key];
        }
        if (key === 'page' && !isValidPageQuery(queryPropList[key])) {
          delete result[key];
        }
      });

      return result;
    }
  }
};
