<template>
  <div class="entry">
    <!-- <div id="dio-title" v-if="item.topic">
      <strong id="entrytopic">
        {{ item.topic.topic }}
      </strong>
    </div> -->
    <div id="entryhtml" class="mt-3 whitespace-pre-wrap " v-text="item.entry.entryData"></div>
    <div class="flex break-word my-2" v-if="item.entry.entryImageUrls && item.entry.entryImageUrls.length">
      <div v-for="(img, index) in item.entry.entryImageUrls" :key="index" class="w-1/5">
        <img :src="getImageUrl(img, '75x75')" class="rounded image-entry pl-0 p-2" />
      </div>
    </div>
  </div>
</template>
<script>
import { generateImageUrl } from '@/utils/imageHash';
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    // todo: utils/imageUtils gibi refactor et
    getImageUrl(image, size = '300x0') {
      let params = {
        size: size,
        quality: '80',
        imageUrl: image,
        gifToImage: true
      };
      var newUrl = generateImageUrl(params);
      return newUrl;
    }
  }
};
</script>
