<template>
  <div>
    <vs-table stripe :multiple="false" v-model="selected" :sst="true" :max-items="50" :data="list" noDataText="">
      <template slot="thead">
        <vs-th sort-key="action">İşlem</vs-th>
        <vs-th sort-key="actionType">İşlem Tipi</vs-th>
        <vs-th sort-key="user">İşlem Yapan</vs-th>
        <vs-th sort-key="createdAt">Tarih</vs-th>
        <vs-th class="flex justify-end"></vs-th>
      </template>
      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td id="action" class="max-w-sm overflow-auto min-w-64">
              <template v-if="tr.redirectType === 'ENTRY'">
                <EntryItem :item="tr.redirectData"></EntryItem>
              </template>
              <template v-if="tr.redirectType === 'TOPIC'">
                <div>{{ tr.redirectData.topic }}</div>
              </template>
              <template v-if="tr.redirectType === 'USER'">
                <div>{{ tr.redirectData.username }}</div>
              </template>
            </vs-td>

            <vs-td id="actionType" class="break-word max-w-sm overflow-auto min-w-64">
              <div class="whitespace-pre-wrap ">{{ tr.actionText }}</div>
            </vs-td>

            <vs-td id="user">
              {{ tr.admin.username }}
            </vs-td>

            <vs-td id="createdAt">
              <div class="font-medium min-w-16">
                {{ tr.updatedAt | moment('DD-MM-YYYY') }}
                <br />
                {{ tr.updatedAt | moment('HH:mm') }}
              </div>
            </vs-td>

            <vs-td id="actions">
              <div class="flex justify-end">
                <vx-tooltip text="İşlemler" v-if="tr.redirectType === 'ENTRY'">
                  <vs-button
                    @click="handleEntryGoto($event, tr.redirectData.id)"
                    color="secondary"
                    size="small"
                    class="btn btn--action"
                    type="gradient"
                    icon="open_in_browser"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="İşlemler" v-if="tr.redirectType === 'USER'">
                  <vs-button
                    @click="handleUserGoto($event, tr.redirectData.onedio_user_id)"
                    color="secondary"
                    size="small"
                    class="btn btn--action"
                    type="gradient"
                    icon="open_in_browser"
                  ></vs-button>
                </vx-tooltip>
                <vx-tooltip text="İşlemler" v-if="tr.redirectType === 'TOPIC'">
                  <vs-button
                    @click="handleTopicGoto($event, tr.redirectData.id)"
                    color="secondary"
                    size="small"
                    class="btn btn--action"
                    type="gradient"
                    icon="open_in_browser"
                  ></vs-button>
                </vx-tooltip>
              </div>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import EntryItem from '../entry/EntryItem.vue';
export default {
  components: { EntryItem },
  props: ['list'],
  data: () => ({
    selected: []
  }),
  methods: {
    handleEntryGoto($event, id) {
      this.$router.push({ name: 'entry', query: { id } });
    },

    handleUserGoto($event, id) {
      this.$router.push({ name: 'user', query: { id } });
    },

    handleTopicGoto($event, id) {
      this.$router.push({ name: 'topic', query: { id } });
    }
  }
};
</script>
