var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          attrs: {
            stripe: "",
            multiple: false,
            sst: true,
            "max-items": 50,
            data: _vm.list,
            noDataText: ""
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return [
                  _c(
                    "tbody",
                    _vm._l(data, function(tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr, attrs: { data: tr } },
                        [
                          _c(
                            "vs-td",
                            {
                              staticClass: "max-w-sm overflow-auto min-w-64",
                              attrs: { id: "action" }
                            },
                            [
                              tr.redirectType === "ENTRY"
                                ? [
                                    _c("EntryItem", {
                                      attrs: { item: tr.redirectData }
                                    })
                                  ]
                                : _vm._e(),
                              tr.redirectType === "TOPIC"
                                ? [
                                    _c("div", [
                                      _vm._v(_vm._s(tr.redirectData.topic))
                                    ])
                                  ]
                                : _vm._e(),
                              tr.redirectType === "USER"
                                ? [
                                    _c("div", [
                                      _vm._v(_vm._s(tr.redirectData.username))
                                    ])
                                  ]
                                : _vm._e()
                            ],
                            2
                          ),
                          _c(
                            "vs-td",
                            {
                              staticClass:
                                "break-word max-w-sm overflow-auto min-w-64",
                              attrs: { id: "actionType" }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "whitespace-pre-wrap " },
                                [_vm._v(_vm._s(tr.actionText))]
                              )
                            ]
                          ),
                          _c("vs-td", { attrs: { id: "user" } }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(tr.admin.username) +
                                "\n          "
                            )
                          ]),
                          _c("vs-td", { attrs: { id: "createdAt" } }, [
                            _c("div", { staticClass: "font-medium min-w-16" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(tr.updatedAt, "DD-MM-YYYY")
                                  ) +
                                  "\n              "
                              ),
                              _c("br"),
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("moment")(tr.updatedAt, "HH:mm")
                                  ) +
                                  "\n            "
                              )
                            ])
                          ]),
                          _c("vs-td", { attrs: { id: "actions" } }, [
                            _c(
                              "div",
                              { staticClass: "flex justify-end" },
                              [
                                tr.redirectType === "ENTRY"
                                  ? _c(
                                      "vx-tooltip",
                                      { attrs: { text: "İşlemler" } },
                                      [
                                        _c("vs-button", {
                                          staticClass: "btn btn--action",
                                          attrs: {
                                            color: "secondary",
                                            size: "small",
                                            type: "gradient",
                                            icon: "open_in_browser"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleEntryGoto(
                                                $event,
                                                tr.redirectData.id
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                tr.redirectType === "USER"
                                  ? _c(
                                      "vx-tooltip",
                                      { attrs: { text: "İşlemler" } },
                                      [
                                        _c("vs-button", {
                                          staticClass: "btn btn--action",
                                          attrs: {
                                            color: "secondary",
                                            size: "small",
                                            type: "gradient",
                                            icon: "open_in_browser"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleUserGoto(
                                                $event,
                                                tr.redirectData.onedio_user_id
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                tr.redirectType === "TOPIC"
                                  ? _c(
                                      "vx-tooltip",
                                      { attrs: { text: "İşlemler" } },
                                      [
                                        _c("vs-button", {
                                          staticClass: "btn btn--action",
                                          attrs: {
                                            color: "secondary",
                                            size: "small",
                                            type: "gradient",
                                            icon: "open_in_browser"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.handleTopicGoto(
                                                $event,
                                                tr.redirectData.id
                                              )
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ])
                        ],
                        1
                      )
                    }),
                    1
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.selected,
            callback: function($$v) {
              _vm.selected = $$v
            },
            expression: "selected"
          }
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", { attrs: { "sort-key": "action" } }, [
                _vm._v("İşlem")
              ]),
              _c("vs-th", { attrs: { "sort-key": "actionType" } }, [
                _vm._v("İşlem Tipi")
              ]),
              _c("vs-th", { attrs: { "sort-key": "user" } }, [
                _vm._v("İşlem Yapan")
              ]),
              _c("vs-th", { attrs: { "sort-key": "createdAt" } }, [
                _vm._v("Tarih")
              ]),
              _c("vs-th", { staticClass: "flex justify-end" })
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }